/* eslint-disable unicorn/filename-case */
/* eslint-disable no-empty */
/* eslint-disable no-undef */
import {insertScriptTagBeforeAnotherScript} from '@banco-de-chile/travel/src/utils/dom-manipulation';
import {removeCookie} from '@banco-de-chile/travel/src/utils/cookies';

const setDyUserActiveConsent = action => {
  try {
    if (DY && (action.type === 'initComplete' || action.type === 'getApplicationPageResolved')) {
      if (localStorage.getItem('cookies-consent-accepted') === 'true') {
        DY.userActiveConsent = {accepted: true};
        insertScriptTagBeforeAnotherScript(
          'dy-user-active-consent-script',
          'window.DY = window.DY || {}; DY.userActiveConsent = {accepted: true};',
          '/api_dynamic.js'
        );
      } else {
        DY.userActiveConsent = {accepted: false};
        insertScriptTagBeforeAnotherScript(
          'dy-user-active-consent-script',
          'window.DY = window.DY || {}; DY.userActiveConsent = {accepted: false};',
          '/api_dynamic.js'
        );
        removeCookie('_dyid_server', '/');
      }
    }
  } catch (error) {
    console.error(`Error setting DY user active consent | ${error}`);
  }
};

export default setDyUserActiveConsent;
