const insertScriptTagBeforeAnotherScript = (scriptId, codeToAppend, partialSrcToSearch) => {
  try {
    const existingScript = document.querySelector(`script[data-dynamic-script="${scriptId}"]`);
    const scriptTagToAppend = document.createElement('script');
    scriptTagToAppend.dataset.dynamicScript = scriptId;
    scriptTagToAppend.innerHTML = codeToAppend;

    if (existingScript) {
      existingScript.parentNode.insertBefore(scriptTagToAppend, existingScript);
      existingScript.remove();
    } else {
      const allScripts = document.querySelectorAll('script');

      for (let i = 0; i < allScripts.length; i++) {
        const script = allScripts[i];
        if (script.src && script.src.includes(partialSrcToSearch)) {
          script.parentNode.insertBefore(scriptTagToAppend, script);
          break;
        }
      }
    }
  } catch (error) {
    console.error(`Error inserting script tag before another script | ${error}`);
  }
};

export {insertScriptTagBeforeAnotherScript};
